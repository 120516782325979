<template>
    <div class="services">
        <section class="background">
            <bxs-layout>
                <div class="text-center">
                    <!-- <p class="h5 text-ui">Sottotitolo</p> -->
                    <h1 class="mt-4">Punti di maglia<br>che produciamo</h1>
                </div>
            </bxs-layout>
        </section>

        <section class="background">
            <bxs-layout>
              <bxs-figure src="/img/hero-ronchipiero.jpg" ratio="21/9"></bxs-figure>
              <div style="margin-top: 7px;"></div>
              <i style="font-size: 13px;">Alessandra Arecco - Acquarello – 1992 - Dalla tradizione al futuro - Opera su soggetto della ditta Ronchi Piero (Milano)</i>
            </bxs-layout>
        </section>

        <section>
            <bxs-layout>
                <bxs-row>
                    <bxs-col cols="12" ds="8">
                        <div class="mb-ui">
                            <p>Produciamo il tessuto d’intreccio maglia lavorata in trama con l’impiego di telai circolari<br>per le tipologie single jersey e double jersey.<br><br>Utilizziamo esclusivamente filati di puro cotone con eventuali filati di complemento.
                              <br><br>
                              La produzione è svolta in partnership con un'Azienda di alto valore tecnico.
                            </p>
                        </div>

                        <div>
                            <p>Il catalogo attuale è composto dai punti di maglia:</p>

                            <ul>
                                <li>
                                    <p>• Jersey</p>
                                    <p>• Piquet</p>
                                    <p>• Felpa</p>
                                    <p>• Spugna</p>
                                    <p>• Costina</p>
                                    <p>• Interlock</p>
                                </li>
                            </ul>
                        </div>
                    </bxs-col>

                    <bxs-col cols="12" ds="6">
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'services'
}
</script>
