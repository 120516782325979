<template>
    <div class="privacy-policy">
    <section>
        <bxs-layout>
        </bxs-layout>
    </section>
    </div>
</template>

<script>
export default {
    name: 'privacypolicy'
}
</script>
