import { createRouter, createWebHistory } from 'vue-router'
import store from '@/store'

import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Services from '../views/Services.vue'
import Links from '../views/Links.vue'
import Error404 from '../views/Error404.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import CookiePolicy from '../views/CookiePolicy.vue'

const routes = [
    {
        path: '/',
        name: 'home',
        component: Home
    },
    {
        path: '/la-storia',
        name: 'about',
        component: About
    },
    {
        path: '/punti-di-maglia',
        name: 'services',
        component: Services
    },
    {
        path: '/link',
        name: 'links',
        component: Links
    },
    {
        path: '/privacy-policy',
        name: 'privacypolicy',
        component: PrivacyPolicy
    },
    {
        path: '/cookie-policy',
        name: 'cookiepolicy',
        component: CookiePolicy
    },
    //
    {
        path: '/:pathMatch(.*)*',
        redirect: '/errors/404'
    },
    {
        path: '/errors/404',
        name: 'error_404',
        component: Error404
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return { top: 0 }
    }
})

router.beforeEach((to, from, next) => {
    console.log('> router.beforeEach', !!store.state.access, to.path, to.name)

    store.commit('set_is_in_transition', true)
    store.commit('set_page_enter', to.name)
    store.commit('set_page_leave', from && from.name ? from.name : null)

    return next()
})

export default router
