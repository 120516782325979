import { gsap } from 'gsap'

const transitions = new Map()

// ------------------------------------------------------------------------------------------------------
const fallback = (el, direction, next) => {
    const isIn = direction === 'in'

    const tl = gsap.timeline({
        onComplete: next
    })

    tl
        .addLabel('start')
        .fromTo(el, {
            autoAlpha: isIn ? 0 : 1,
            scaleX: isIn ? 1.05 : 1
        }, {
            autoAlpha: isIn ? 1 : 0,
            scaleX: isIn ? 1 : 1.05,
            duration: 1,
            clearProps: 'scaleX',
            ease: 'expo.out'
        })
}

// ------------------------------------------------------------------------------------------------------

[{
    key: 'fallback',
    timeline: fallback
}].forEach((item) => {
    transitions.set(
        item.key,
        (ctx, direction) => new Promise(resolve => item.timeline(ctx, direction, resolve))
    )
})

export default transitions