<template>
    <div class="about">
        <section class="background">
            <bxs-layout>
                <div class="text-center">
                    <p class="h5 text-ui">dal 1949</p>
                    <h1 class="mt-4">La storia</h1>
                </div>
            </bxs-layout>
        </section>

        <section class="background">
            <bxs-layout>
                <bxs-figure src="/img/hero-ronchipiero.jpg" ratio="21/9"></bxs-figure>
                <div style="margin-top: 7px;"></div>
                <i style="font-size: 13px;">Alessandra Arecco - Acquarello – 1992 - Dalla tradizione al futuro - Opera su soggetto della ditta Ronchi Piero (Milano)</i>
            </bxs-layout>
        </section>

        <section class="background">
            <bxs-layout>
                <bxs-row>
                    <bxs-col
                    cols="12"
                    ds="9">
                        <div class="text-ui mb-ui">
                            <p>L’azienda nasce nell’anno 1949 per volontà dei miei genitori Bambina Carugo e Piero Ronchi, che hanno nel mondo tessile, familiarità sia di luoghi sia di Persone.</p>
                            <p>Lo slancio a dar vita all’impresa, viene Loro da due sentimenti:</p>
                            <p>dal fervido clima di ricostruzione e di industrializzazione della Nazione, dopo l’anno 1945</p>
                            <p>dall’entusiasmante richiesta di tessuto a maglia – detto anche tessuto Jersey – che in virtù del suo piacevole comfort veniva ad essere impiegato nell’abbigliamento sempre di più.</p>
                            <p>Prende il nome Ronchi Piero e viene insediata in Milano, ove rimarrà fino all’anno 2011 per poi trasferirsi nella sede attuale.</p>
                            <p>L'azienda con l'anno 2023, è nel suo 75° anno di vita.</p>
                        </div>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>

        <section id="section_timeline">
            <bxs-layout max-width="1280">
                <div class="mb-ui text-center background">
                    <h2>Una storia <br> di processo <br> e innovazione</h2>

                    <div class="background text-primary py-ui mb-ui">
                        <bxs-layout>
                            <ul class="flex justify-center align-center text-center ma-0">
                                <li class="text-mute text-400 mr-ui">Anni</li>
                                <li
                                v-for="n in 9"
                                :key="n"
                                class="mr-ui"
                                @click="$root.scroll.scrollTo('#about-t-' + n)">
                                    <a role="button">200{{ n }}</a>
                                </li>
                            </ul>
                        </bxs-layout>
                    </div>
                </div>

                <div
                data-scroll
                data-scroll-sticky
                data-scroll-target="#section_timeline"
                class="timeline">
                    <div class="timeline--line"></div>
                    <div class="timeline--fade-top"></div>
                    <div class="timeline--fade-bottom"></div>

                    <ul
                    id="timeline-items"
                    class="timeline--items">
                        <li
                        v-for="n in 9"
                        :key="n"
                        :id="'timeline-item-' + n"
                        :class="['timeline--item', { 'timeline--item-actived': active === n }]">
                            <div
                            data-scroll
                            data-scroll-sticky
                            :data-scroll-target="'#timeline-item-' + n"
                            class="timeline--item--spacer"><div></div></div>

                            <!-- <div
                            data-scroll
                            data-scroll-sticky
                            :data-scroll-target="'#timeline-item-' + n"
                            class="timeline--item--line-progress"></div> -->

                            <bxs-row>
                                <bxs-col
                                cols="2"
                                ds="6"
                                class="py-0">
                                    <div
                                    data-scroll
                                    data-scroll-sticky
                                    :data-scroll-call="n"
                                    data-scroll-repeat="true"
                                    :data-scroll-target="'#timeline-item-' + n">
                                        <h2>200{{ n }}</h2>
                                    </div>
                                </bxs-col>

                                <bxs-col
                                cols="10"
                                ds="6"
                                class="py-0">
                                    <div style="padding-left: 10%;">
                                        <div class="mb-12">
                                            <small>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Unde consectetur numquam neque architecto maiores nostrum similique cumque, officia, fugit enim harum, nam totam saepe. Possimus dolorum nulla placeat id laborum.</small>
                                        </div>

                                        <!-- :src="imgs[Math.floor(Math.random() * imgs.length)]" -->
                                        <bxs-figure
                                        ratio="4/3"
                                        :src="imgs[n]" />
                                    </div>
                                </bxs-col>
                            </bxs-row>
                        </li>
                    </ul>
                </div>
            </bxs-layout>
        </section>

    </div>
</template>

<script>
export default {
    name: 'about',
    data () {
        return {
            active: 1,
            scroll_progress: 0,
            imgs: [
                'https://images.pexels.com/photos/236748/pexels-photo-236748.jpeg?auto=compress&cs=tinysrgb&w=1600',
                'https://images.pexels.com/photos/365067/pexels-photo-365067.jpeg?auto=compress&cs=tinysrgb&w=1600',
                'https://images.pexels.com/photos/8030144/pexels-photo-8030144.jpeg?auto=compress&cs=tinysrgb&w=1600',
                'https://images.pexels.com/photos/3799153/pexels-photo-3799153.jpeg?auto=compress&cs=tinysrgb&w=1600',
                'https://images.pexels.com/photos/6766232/pexels-photo-6766232.jpeg?auto=compress&cs=tinysrgb&w=1600',
                'https://images.pexels.com/photos/6766232/pexels-photo-6766232.jpeg?auto=compress&cs=tinysrgb&w=1600',
                'https://images.pexels.com/photos/236748/pexels-photo-236748.jpeg?auto=compress&cs=tinysrgb&w=1600',
                'https://images.pexels.com/photos/365067/pexels-photo-365067.jpeg?auto=compress&cs=tinysrgb&w=1600',
                'https://images.pexels.com/photos/6766232/pexels-photo-6766232.jpeg?auto=compress&cs=tinysrgb&w=1600',
                'https://images.pexels.com/photos/236748/pexels-photo-236748.jpeg?auto=compress&cs=tinysrgb&w=1600',
                'https://images.pexels.com/photos/6766232/pexels-photo-6766232.jpeg?auto=compress&cs=tinysrgb&w=1600'

            ]
        }
    },
    mounted () {
        this.$nextTick(() => {
            this.$emitter.on('scroll-call', (v) => {
                this.active = parseInt(v)
            })
        })
    }
}
</script>

<style>
  /* TIMELINE HIDDEN */
  #section_timeline{
    display: none !important;
    height: 0 !important;
    width: 0 !important;
    visibility: hidden;
  }
  .about{
    padding-bottom: 0 !important;
  }
  /* TIMELINE HIDDEN END */
</style>
