<template>
    <!-- v-sticky -->
    <header class="bxs-navbar">
        <bxs-layout>
            <!-- desktop nav -->
            <bxs-row
            no-gutters
            align="center">
                <bxs-col cols="6">
                    <logo />
                </bxs-col>

                <bxs-col
                cols="6"
                class="mx-auto text-right">
                    <bxs-menu
                    :activator-props="{
                        icon: true,
                        color: 'mute'
                    }"
                    activator-icon="hamburger"
                    title=""
                    close-on-click>
                        <template #activator="{ on, value }">
                            <div
                            v-on="on"
                            :class="['menu-toggle', { 'menu-toggle-active': value }]">
                                <div class="hamburger">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                                <div class="cross">
                                    <span></span>
                                    <span></span>
                                </div>
                            </div>
                        </template>

                        <bxs-list class="mb-ui">
                            <bxs-list-item to="/">Home</bxs-list-item>
                            <bxs-list-item to="/la-storia">La storia</bxs-list-item>
                            <bxs-list-item to="/punti-di-maglia">Punti di maglia</bxs-list-item>
                            <bxs-list-item to="/link">Link utili</bxs-list-item>
                        </bxs-list>

                        <bxs-layout class="mt-12">
                            <div>
                                <div class="mb-ui">
                                    <span class="bxs-d-block mb-2 colored">Ronchi Piero<br>di Cipriano Ronchi</span>
                                </div>

                                <div class="text-mute mb-ui">
                                    <span class="bxs-d-block mb-1 colored">Via San Carlo 14,<br>I-20811 Cesano Maderno - MB</span>
                                </div>

                                <div class="text-mute mb-ui">
                                    <span class="colored">Telefono</span> <a href="tel:+393480590841" class="colored">+39/348 059 084 1</a>
                                    <br>
                                    <a href="mailto:cipriano@ronchipiero.it" class="colored">cipriano@ronchipiero.it</a>
                                    <br>
                                    <a href="http://www.ronchipiero.it" class="bxs-d-block mb-2 colored">www.ronchipiero.it</a>
                                </div>

                                <div class="text-mute mb-ui">
                                    <div>
                                        <small class="colored">© {{ new Date().getFullYear() }} Ronchi Piero</small>
                                        <span class="mx-2">-</span>
                                        <small class="colored">part.iva IT12131930153</small>
                                        <br>
                                        <small class="colored">cod.fisc RNCCRN43B25H601U</small>
                                    </div>
                                </div>
                            </div>
                        </bxs-layout>
                    </bxs-menu>
                </bxs-col>
            </bxs-row>
        </bxs-layout>
    </header>
</template>

<script>
export default {
    name: 'bxs-navbar'
}
</script>

<style lang="scss" scoped>
.colored{
  color: #009b7f;
}
.bxs-navbar {
    position: relative;
    width: 100%;
    min-height: var(--navbar-min-height);

    background-color: var(--navbar-background-color);

    display: flex;
    align-items: center;

    border-top-left-radius: var(--radius);
    border-top-right-radius: var(--radius);

    z-index: var(--layer-navbar);

    transition: transform 0.5s ease-out;

    > .bxs-layout {
        height: 100%;
        padding-top: var(--navbar-padding-y);
        padding-bottom: var(--navbar-padding-y);

        > .bxs-row {
            height: 100%;

            .bxs-col {
                height: 100%;
            }
        }
    }
}

//
.menu-toggle {
	flex:0 0 auto;
	width: 40px;
	height: 40px;
	border: 1px solid #009b7f;
	cursor: pointer;
	position:relative;
}

.hamburger,
.cross{
	position:absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.hamburger span{
	display:block;
	width: 18px;
	height: 1px;
	margin-bottom: 4px;
	overflow: hidden;
	position: relative;
}

.hamburger span:last-child{
	margin:0;
}

.hamburger span:before,.hamburger span:after{
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	background-color: rgb(0, 0, 0);
	transform: translateX(-200%);
	transition:transform ease 300ms;
}

.hamburger span:after{
	transform: translateX(0);
}

.hamburger span:nth-child(2):before,
.hamburger span:nth-child(2):after{
	transition-delay: 75ms;
}

.hamburger span:last-child:before,
.hamburger span:last-child:after{
	transition-delay: 150ms;
}

.menu-toggle:hover .hamburger span:before{
	transform: translateX(0);
}

.menu-toggle:hover .hamburger span:after{
	transform: translateX(200%);
}

.menu-toggle.menu-toggle-active .hamburger span:before{
	transform: translateX(100%);
}

.menu-toggle.menu-toggle-active .hamburger span:after{
	transform: translateX(200%);
}

.cross span {
	display: block;
	width: 18px;
	height: 2px;
	background-color: rgb(0, 0, 0);
	transform: translateY(50%) rotate(45deg) scaleX(0);
	transition: transform ease 200ms;
}

.cross span:last-child{
	transform: translateY(-50%) rotate(-45deg) scaleX(0);
}

.menu-toggle.menu-toggle-active .cross span{
	transition-delay: 450ms;
	transform: translateY(50%) rotate(45deg) scaleX(1);
}

.menu-toggle.menu-toggle-active .cross span:last-child{
	transform: translateY(-50%) rotate(-45deg) scaleX(1);
}
</style>
