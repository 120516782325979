<template>
    <div class="err-404">
        <section>
            <bxs-layout class="text-center">
                <h1>Ops, error 404</h1>
                <p>Pagina non trovata</p>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'err404'
}
</script>
