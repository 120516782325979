import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'

import components from './components'
import directives from './directives'
import filters from './filters'
import { useToast } from 'vue-toastification'
import mitt from 'mitt'

const emitter = mitt()
const app = createApp(App)

app.config.globalProperties.$filters = filters
app.config.globalProperties.$emitter = emitter

components(app)
directives(app)

app.config.globalProperties.$toast = useToast()

app.config.errorHandler = (err, vm, info) => {
    console.log('error handler', err, vm, info)
    // const payload = Object.values(err.payload || {})
    let message = err.message || err.code || err

    if (err.code === 'resource.validation') {
        message += Object.values(err.fields)
    }

    vm.$toast.error(String(message))
}

app.use(store).use(router).mount('#app')