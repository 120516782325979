<template>
    <div class="links">
        <section class="background">
            <bxs-layout>
                <div class="text-center">
                    <!-- <p class="h5 text-ui">Sottotitolo</p> -->
                    <h1 class="mt-4">Link utili</h1>
                </div>
            </bxs-layout>
        </section>

        <section class="background">
            <bxs-layout>
              <bxs-figure src="/img/hero-ronchipiero.jpg" ratio="21/9"></bxs-figure>
              <div style="margin-top: 7px;"></div>
              <i style="font-size: 13px;">Alessandra Arecco - Acquarello – 1992 - Dalla tradizione al futuro - Opera su soggetto della ditta Ronchi Piero (Milano)</i>
            </bxs-layout>
        </section>

        <section>
            <bxs-layout max-width="1024px">
                <bxs-row align="center">
                    <bxs-col cols="12" ds="6">
                        <p>Per approfondire sulla lavorazione dei tessuti a maglia:</p>

                        <ul>
                            <li>
                                <a href="http://it.wikipedia.org/wiki/Lavorazione_a_maglia">http://it.wikipedia.org/wiki/Lavorazione_a_maglia</a>
                            </li>
                            <li>
                                <a href="http://it.wikipedia.org/wiki/Storia_del_lavoro_a_maglia">http://it.wikipedia.org/wiki/Storia_del_lavoro_a_maglia</a>
                            </li>
                        </ul>
                    </bxs-col>

                    <bxs-col cols="12" ds="6">
                        <bxs-figure src="/img/links/7.jpg" width="250"/>
                    </bxs-col>
                </bxs-row>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
  name: 'links'
}
</script>
