<template>
    <div
    ref="wrapper"
    id="wrapper"
    data-scroll-container
    :class="[
        'app--wrapper',
        scroll_direction ? 'app-scroll-direction-' + scroll_direction : '',
        {
            'app-loading': is_in_transition || is_loading,
            'app-noevt': is_in_transition || is_loading
        }
    ]">
        <preloader
        v-if="!is_preloaded"
        ref="preloader"
        :resources="resources"
        @complete="preloadComplete">
            <logo max-width="140px" />
        </preloader>

        <bxs-navbar
        v-if="is_preloaded"
        ref="navframe" />

        <!-- view -->
        <main
        ref="main"
        class="app--main">
            <router-view
            v-slot="{ Component }"
            class="app--page">
                <transition
                @enter="enterTransition"
                @leave="leaveTransition"
                mode="out-in"
                appear>
                    <component
                    v-if="is_preloaded"
                    ref="page"
                    id="page"
                    :is="Component"
                    :key="$route.name" />
                </transition>
            </router-view>

            <transition
            name="fade"
            mode="out-in">
                <bxs-loader
                v-if="is_preloaded && is_loading"
                fullscreen />
            </transition>
        </main>

        <bxs-footer
        v-if="is_preloaded"
        ref="footer" />

        <bxs-coookies
        v-if="is_preloaded"
        buttonText="Accept"
        storageName="https://ronchipiero.it:cookie:accepted">
            <template #default="{ accept }">
                <div class="flex-1 text-ui">
                    <div class="mb-8">
                        <small class="text-600">Notice!</small>
                    </div>

                    <div class="mb-ui">
                        <p>Questo sito non utilizza cookie di profilazione. Si fa invece ricorso a cookie tecnici, anche di terze parti, al fine di migliorare l'esperienza di navigazione. <br> Cliccando sul tasto “Rifiuta”, chiuderai il banner senza prestare il consenso ad alcun cookie, ad eccezione di quelli necessari.</p>
                    </div>

                    <div class="text-right">
                        <bxs-btn
                        pill
                        min-width="80"
                        class="mr-4"
                        @click="accept">Accetta</bxs-btn>

                        <bxs-btn
                        pill
                        min-width="80"
                        class="mr-4"
                        @click="accept">Rifiuta</bxs-btn>

                        <bxs-menu title="Impostazioni cookie">
                            <template #activator="{ on }">
                                <bxs-btn
                                v-on="on"
                                pill
                                outlined
                                min-width="80"
                                @click="cookie.on = true">Impostazioni</bxs-btn>
                            </template>

                            <div>
                                <div class="pa-ui">
                                    <logo max-width="80" />
                                </div>

                                <div class="bxs-divider"></div>

                                <div class="pa-ui">
                                    <p class="text-ui text-500 h6">Cookie strettamente necessari</p>
                                    <p>I cookie strettamente necessari dovrebbero essere sempre attivati per poter salvare le tue preferenze per le impostazioni dei cookie.</p>

                                    <bxs-switch-field
                                    label="Attivato"
                                    :model-value="true"
                                    disabled
                                    class="mt-ui" />
                                </div>

                                <div class="bxs-divider"></div>

                                <div class="pa-ui">
                                    <p class="text-ui text-500 h6">Cookie di terze parti</p>
                                    <p>Google Maps è un servizio di visualizzazione di mappe gestito da Google che permette a questo sito di indicare il luogo dove si trova l'azienda.</p>

                                    <bxs-switch-field
                                    label="Disattivato"
                                    class="mt-ui" />
                                </div>

                                <div class="bxs-divider"></div>
                            </div>

                            <template #actions>
                                <div class="px-ui">
                                    <bxs-btn
                                    pill
                                    light
                                    min-width="80"
                                    @click="accept">Salva le modifiche</bxs-btn>
                                </div>
                            </template>
                        </bxs-menu>
                    </div>
                </div>
            </template>
        </bxs-coookies>
    </div>
</template>

<script>
import { mapState } from 'vuex'
import { fixVh } from '@/assets/libs/utils'
import transitions from '@/assets/libs/transitions'
import LocoScroll from 'locomotive-scroll'

import Cookie from '@/components/Cookie.vue'
import Preloader from '@/components/Preloader.vue'
import Navbar from '@/components/layout/Navbar.vue'
import Footer from '@/components/layout/Footer.vue'

export default {
    name: 'App',
    components: {
        preloader: Preloader,
        'bxs-navbar': Navbar,
        'bxs-footer': Footer,
        'bxs-coookies': Cookie
    },
    computed: {
        ...mapState({
            is_loading: (state) => state.is_loading,
            is_preloaded: (state) => state.is_preloaded,
            is_in_transition: state => state.is_in_transition
        })
    },
    data () {
        return {
            cookie: {
                on: false
            },
            resources: [
                {
                    type: 'ìmg',
                    url: 'https://images.pexels.com/photos/236748/pexels-photo-236748.jpeg?auto=compress&cs=tinysrgb&w=1600'
                },
                {
                    type: 'ìmg',
                    url: 'https://images.pexels.com/photos/365067/pexels-photo-365067.jpeg?auto=compress&cs=tinysrgb&w=1600'
                },
                {
                    type: 'ìmg',
                    url: 'https://images.pexels.com/photos/8030144/pexels-photo-8030144.jpeg?auto=compress&cs=tinysrgb&w=1600'
                },
                {
                    type: 'ìmg',
                    url: 'https://images.pexels.com/photos/3799153/pexels-photo-3799153.jpeg?auto=compress&cs=tinysrgb&w=1600'
                },
                {
                    type: 'ìmg',
                    url: 'https://images.pexels.com/photos/6766232/pexels-photo-6766232.jpeg?auto=compress&cs=tinysrgb&w=1600'
                }
            ],
            scroll: null,
            scroll_direction: null
        }
    },
    mounted () {
        this.$nextTick(this.start)
    },
    watch: {
        is_in_transition (newVal) {
            if (newVal) this.pause()
            else this.play()
        }
    },
    methods: {
        async start () {
            console.log('app start()')

            this.resize()
            window.addEventListener('resize', this.resize)

            await this.$store.dispatch('load_imgs', [
                'https://images.pexels.com/photos/236748/pexels-photo-236748.jpeg?auto=compress&cs=tinysrgb&w=1600',
                'https://images.pexels.com/photos/365067/pexels-photo-365067.jpeg?auto=compress&cs=tinysrgb&w=1600',
                'https://images.pexels.com/photos/8030144/pexels-photo-8030144.jpeg?auto=compress&cs=tinysrgb&w=1600',
                'https://images.pexels.com/photos/3799153/pexels-photo-3799153.jpeg?auto=compress&cs=tinysrgb&w=1600',
                'https://images.pexels.com/photos/6766232/pexels-photo-6766232.jpeg?auto=compress&cs=tinysrgb&w=1600'
            ])

            this.$refs.preloader.start()
        },
        preloadComplete () {
            console.log('app preloadComplete()')

            this.$store.commit('set_is_preloaded', true)
            this.$store.commit('set_is_loading', false)
        },
        play () {
            this.$nextTick(() => {
                console.log('app play()', this.$refs)

                // Note: scroll-sections are optional but recommended to improve performance, particularly in long pages.
                const sections = [this.$refs.footer.$el, ...this.$refs.page.$el.querySelectorAll('section')]
                if (sections.length > 0) {
                    sections.forEach((el) => {
                        if (!el.hasAttribute('data-scroll-section')) el.setAttribute('data-scroll-section', '')
                    })
                }

                if (!this.scroll) {
                    this.scroll = new LocoScroll({
                        el: this.$refs.wrapper,
                        smooth: true,
                        initPosition: { x: 0, y: 0 },
                        direction: 'vertical',
                        gestureDirection: 'vertical',
                        lerp: 0.1,
                        getDirection: true,
                        getSpeed: false,
                        multiplier: 2,
                        firefoxMultiplier: 25,
                        touchMultiplier: 4,
                        scrollFromAnywhere: false,
                        tablet: { direction: 'vertical', smooth: true },
                        smartphone: { direction: 'vertical', smooth: true }
                    })
                }

                this.resize()

                this.scroll.on('scroll', this.handleScroll)

                this.scroll.on('call', (val) => {
                    this.$emitter.emit('scroll-call', val)
                })

                const blanks = [...document.querySelectorAll(['a[target="_blank"]'])]
                blanks.forEach((el) => {
                    if (!el.hasAttribute('rel')) el.setAttribute('rel', 'noopener')
                })
            })
        },
        pause () {
            console.log('app pause()')

            if (this.scroll) this.scroll.stop()
        },
        // handlers
        handleScroll (evt) {
            // console.log('handleScroll', evt)
            this.scroll_direction = evt.direction
            this.$emitter.emit('scroll', evt)
        },
        resize () {
            console.log('app resize()')

            fixVh()

            if (this.scroll) {
                this.scroll.update() // Updates all element positions
                this.scroll.start() // Restarts the scroll events
            }

            this.$store.commit('resize')
        },
        // transitions ------------------------------------
        enterTransition (el, next) {
            console.log('app enterTransition()')

            if (this.scroll) this.scroll.scrollTo(0, { duration: 0, disableLerp: true })

            transitions.get('fallback')(el, 'in').then(() => {
                this.$store.commit('set_is_in_transition', false)
                next()
            })
        },
        leaveTransition (el, next) {
            console.log('app leaveTransition()')

            transitions.get('fallback')(el, 'out').then(() => {
                next()
            })
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/styles/index.scss";
</style>
