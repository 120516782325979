<template>
    <div class="cookie-policy">
        <section>
            <bxs-layout>
            </bxs-layout>
        </section>
    </div>
</template>

<script>
export default {
    name: 'cookiepolicy'
}
</script>
