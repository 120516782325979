<template>
    <div class="home-page">
        <section>
            <bxs-layout>
                <div class="text-center">
                    <p class="h5 text-ui">dal 1949</p>
                    <h1 class="mt-4">Produciamo tessuti<br> d'intreccio maglia <br> lavorata in trama</h1>
                </div>
            </bxs-layout>
        </section>

        <section>
          <!--
            <bxs-layout>
                <div class="text-right mb-2">
                    <bxs-btn
                    to="/la-storia"
                    append-icon="arrow-right"
                    text>La nostra storia</bxs-btn>
                </div>
            </bxs-layout>
          -->

          <swiper
          slides-per-view="auto"
          :space-between="2"
          centered-slides
          loop
          grab-cursor>
              <swiper-slide style="width: 300px;">
                  <bxs-figure
                  src="/img/home/1.jpg"
                  ratio="2/3" />
              </swiper-slide>

              <swiper-slide style="width: 300px;">
                  <bxs-figure
                  src="/img/home/3.jpg"
                  ratio="2/3" />
              </swiper-slide>

              <swiper-slide style="width: 300px;">
                  <bxs-figure
                  src="/img/home/2.jpg"
                  ratio="2/3" />
              </swiper-slide>

              <swiper-slide style="width: 300px;">
                  <bxs-figure
                  src="/img/home/4.jpg"
                  ratio="2/3" />
              </swiper-slide>

              <swiper-slide style="width: 300px;">
                  <bxs-figure
                  src="/img/home/5.jpg"
                  ratio="2/3" />
              </swiper-slide>
          </swiper>
        </section>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
import 'swiper/css/navigation'

export default {
    name: 'home',
    components: {
        Swiper,
        SwiperSlide
    },
    data () {
        return {
            itemPerView: 3
        }
    }
}
</script>
