<template>
    <footer :class="['app--footer bxs-footer', { 'bxs-footer-mobile': $store.state.is_mobile }]">
        <bxs-layout class="small">
            <bxs-row
            no-gutters
            align="center">
                <bxs-col
                cols="6"
                ml="4">
                    <small class="mb-1 colored">Via San Carlo 14, <br v-if="$store.state.is_mobile"> 20811 Cesano Maderno (MB)</small>
                </bxs-col>

                <bxs-col
                cols="4"
                ml="4"
                class="bxs-hide-ml text-center">
                    <a href="tel:+393480590841" target="_blank" class="bxs-hide-ds colored"><small>+39/348 059 084 1</small></a>
                    <span class="bxs-hide-ds mx-2">-</span>
                    <a href="mailto:cipriano@ronchipiero.it" class="colored" target="_blank"><small>cipriano@ronchipiero.it</small></a>
                </bxs-col>

                <bxs-col
                cols="6"
                ml="4"
                class="text-right text-mute">
                    <small class="colored">© {{ new Date().getFullYear() }} Ronchi Piero</small>
                    <br v-if="$store.state.is_mobile">
                    <span v-else class="mx-2 colored">-</span>
                    <small class="colored">P.IVA IT12131930153</small>
                </bxs-col>
            </bxs-row>
        </bxs-layout>
    </footer>
</template>

<script>
export default {
    name: 'bxs-footer'
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/mq.scss';

footer {
    position: relative;
    width: 100%;
    min-height: var(--footer-height);
    background-color: var(--footer-background-color);
    color: var(--footer-color);

    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    z-index: var(--layer-navbar);
}
.colored{
  color: #009b7f;
}
</style>
