// import moment from 'moment'
import { parse } from 'marked'

// moment.locale('it')

const d = {
    // toHumanDate(value, f = 'L') {
    //     return value && moment(value).isValid() ? moment(value).format(f) : (value || '--')
    // },
    // toHumanCalendar(value) {
    //     return value ? moment(value).calendar() : (value || '--')
    // },
    mdToHtml: v => parse(v),
    truncate(v, l = 115) {
        if (!v) return v
        return v.substring(0, l) + '...'
    },
    highlight(v, k) {
        if (!v || !k) return v
        return v.replace(k, '<span class="text-highlight">' + k + '</span>')
    },
    toStaticFile(v) {
        if (!v) return v
        // const env = process.env.NODE_ENV
        // if (v.startsWith('http')) v = v.replace('http://localhost:8080/', '')
        return `${process.env.VUE_APP_API}/${v}`
    }
}

export default d